var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart card-body" },
    [
      !_vm.loading
        ? _c("apex-chart", {
            attrs: {
              type: "bar",
              height: "450",
              options: _vm.chartOptions,
              series: _vm.series,
              width: _vm.chartWidth,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }