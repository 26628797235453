var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          !_vm.loading
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-sheet",
                            { attrs: { rounded: "", outlined: "" } },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "justify-center text-center my-5 font-weight-bold headline",
                                  staticStyle: { color: "#3F51B5" },
                                },
                                [
                                  _vm.item.SchoolName != null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.item.SchoolName) + " -"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.item.PointTemplateName) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(" BİRLEŞİK FORM KARNESİ "),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-sheet",
                            { attrs: { rounded: "", outlined: "" } },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "justify-center text-center" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.item.StudentFullName) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "justify-center text-center my-0 py-0",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.item.StudentClassBranch) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-card-title"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _vm.showAsessmentAreaChart
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-sheet",
                                { attrs: { rounded: "", outlined: "" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "chart card-body" },
                                    [
                                      _c("apex-chart", {
                                        attrs: {
                                          type: "bar",
                                          height: "450",
                                          width: _vm.chartWidth,
                                          options: _vm.chartOptions,
                                          series: _vm.series,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-sheet",
                            { attrs: { rounded: "", outlined: "" } },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "justify-center text-center" },
                                [_vm._v(" FORMLAR")]
                              ),
                              _c(
                                "v-col",
                                [
                                  !_vm.loading
                                    ? _c("combined-form-report-chart", {
                                        key: _vm.showPoints,
                                        class: _vm.$vuetify.breakpoint.mobile
                                          ? ""
                                          : "mx-10",
                                        attrs: {
                                          coursePoints: _vm.coursePoints,
                                          "show-points": _vm.showPoints,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", {
                        staticClass: "d-flex justify-content-center",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }