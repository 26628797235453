<template>
  <div class="chart card-body">
    <apex-chart
        v-if="!loading"
        type="bar"
        height="450"
        :options="chartOptions"
        :series="series"
        :width="chartWidth"
    ></apex-chart>
  </div>
</template>
<script>

export default {
  props: ['coursePoints', 'showPoints'],
  data() {
    return {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 450,
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            endingShape: 'rounded',
            borderRadius: 3
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        zoom: {
          enabled: true
        },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        xaxis: {
          categories: []
        },
        yaxis: {
          show: false,
          max: 100
        },
        fill: {
          opacity: 1
        },
        toolbar: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        }
      }
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    dynamicWidth() {
      return this.series[0].data.length * 100
    },
    chartWidth() {
      return this.dynamicWidth < window.innerWidth ? '100%' : this.dynamicWidth
    },
  },
  methods: {
    getData() {
      this.series[0] = {name: '', data: []}
      this.series[0].name = 'Formlar'
      this.series[0].data = this.coursePoints.map((item) => item.Point)
      this.chartOptions.xaxis.categories = this.coursePoints.map((item) => item.CourseName)

      this.chartOptions.grid.show = this.showPoints
      this.chartOptions.yaxis.show = this.showPoints
      this.chartOptions.tooltip.enabled = this.showPoints
      
      this.loading = false
    }
  }
}
</script>
