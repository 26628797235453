<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if='!loading'>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <h3 class='justify-center text-center my-5 font-weight-bold headline' style='color: #3F51B5'>
                <span v-if="item.SchoolName != null">{{ item.SchoolName }} -</span> {{ item.PointTemplateName }} <br> BİRLEŞİK FORM KARNESİ
              </h3>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class='justify-center text-center'>
                {{ item.StudentFullName }}
              </v-card-title>
              <v-card-title class='justify-center text-center my-0 py-0'>
                {{ item.StudentClassBranch }}
              </v-card-title>
              <v-card-title>
              </v-card-title>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="showAsessmentAreaChart">
            <v-sheet rounded outlined>
              <div class="chart card-body">
                <apex-chart type='bar' height='450' :width="chartWidth" :options='chartOptions' :series='series'></apex-chart>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class="justify-center text-center"> FORMLAR</v-card-title>
              <v-col>
                <combined-form-report-chart v-if="!loading" :coursePoints="coursePoints" :show-points="showPoints"
                                            :key="showPoints" :class="$vuetify.breakpoint.mobile ? '': 'mx-10'"></combined-form-report-chart>
              </v-col>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-content-center">
            <!--            <v-chip class="justify-content-center" label large color="primary" dark style="width: 150px">-->
            <!--              Puan: {{ coursePoint.Point }}-->
            <!--            </v-chip>-->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment/moment'
import CombinedFormReportChart from "@/view/pages/form/CombinedFormReportChart.vue";

export default {
  name: 'combined-form-user-report',
  components: {CombinedFormReportChart},
  data() {
    return {
      formAssessmentGroupId: this.$route.params.id,
      rows: [],
      loading: true,
      loadingChart: true,
      pointTemplates: [],
      rateWatcher: false,
      item: {},
      coursePoints: [],
      showPoints: true,
      showAsessmentAreaChart: true,
      series: [{name: '', data: []}],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 450,
          width: 550,
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded',
            borderRadius: 3,
          }
        },
        colors: [
          '#3F51B5',
          '#4CAF50',
          '#FFC107',
          '#F44336',
          '#9C27B0',
          '#009688',
          '#607D8B'
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        zoom: {
          enabled: true
        },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        xaxis: {
          categories: ['']
        },
        yaxis: {
          show: false,
          max: 100
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => this.tooltipValues(val),
            title: {
              formatter: (title) => title,
            },
          },
        },
        fill: {
          opacity: 1
        },
        toolbar: {
          show: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        }
      }
    }
  },
  watch: {
    rateWatcher(val) {
      if (val) this.rater(this.rows, this.pointTemplates)
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('DD.MM.YYYY') : ''
    }
  },
  computed: {
    dynamicWidth() {
      return this.series[0].data.length * 100
    },

    chartWidth() {
      return this.dynamicWidth < window.innerWidth ? '100%' : this.dynamicWidth
    },
  },
  created() {
    this.getPointTemplates()
  },
  mounted() {
  },
  methods: {
    rater(rows, pointTemplates) {
      //rate finder method start

      let pointTemplate =
          rows[0].values[0].FormUser.Form.PointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].values[0].FormUser.Form.PointTemplateId)
              : null
      if (pointTemplate) {
        pointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }

      let advancedPointTemplate =
          rows[0].values[0].FormUser.Form.AdvancedPointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].values[0].FormUser.Form.AdvancedPointTemplateId)
              : null
      if (advancedPointTemplate) {
        advancedPointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }

      rows.forEach((row) => {
        row.values.forEach((value) => {
          let rate = (value.Point / value.MaxPoint) * 100
          if (pointTemplate) {
            pointTemplate.PointTemplateSections.map((d) => {
              if (rate >= d.MinValue) value.PointTemplate = d.Name
            })
          }
          if (advancedPointTemplate) {
            advancedPointTemplate.PointTemplateSections.map((d) => {
              if (rate >= d.MinValue) value.AdvancedPointTemplate = d.Name
            })
          }
        })
      })

      this.chartOptions.xaxis.categories = this.rows[0].values.map((d) => d.AssessmentArea.Name)

      this.loading = false
      //rate finder method end
    },
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/FormAssessmentGroup/report', {
        FormAssessmentGroupId: this.formAssessmentGroupId,
      })
          .then((data) => {
            this.rows = this.groupByArray(data.data, 'FormUserId')
            
            if (data.data.length > 0 && data.data[0].HideAssessmentAreaChart)
              this.showAsessmentAreaChart = false

            for (let i = 0; i < this.rows.length; i++) {
              this.coursePoints[i] = {Point: this.rows[i].values[0].FormUser.Point, CourseName: this.rows[i].values[0].FormUser.Form.Name}
              this.series[i] = {name: '', data: []}
              this.series[i].name = this.rows[i].values[0].FormUser.Form.Name
              this.series[i].data = this.rows[i].values.map((d) => d.Point)
            }
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0

            if (this.rows.length > 0) this.getFormDetails(this.rows[0].values[0].FormUser.FormId)
          })
          .catch(({response}) => {
            this.loading = false
            ApiService.showError(response)
          })
    },
    getPointTemplates() {
      ApiService.setHeader()
      ApiService.post('api/PointTemplate', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.pointTemplates = data.data.Results
            this.getDataFromApi()

          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getFormDetails(formId) {
      ApiService.setHeader()
      ApiService.get('api/Form/FormUserReport/' + 0 + '/' + formId)
          .then((data) => {
            this.item = data.data
            this.loadingChart = true
            if (this.item.FormTypeId == 9 || this.item.FormTypeId == 4)
              this.showPoints = false

            this.chartOptions.yaxis.show = this.showPoints
            this.chartOptions.grid.show = this.showPoints

            this.loadingChart = false
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({key: v, values: [x]})
        }
        return rv
      }, [])
    },

    tooltipValues(val) {
      if (this.showPoints)
        return val + ' Puan'
      else
        return ''
    }
  }
}
</script>

<style>
.chart {
  overflow: auto;
}
</style>